@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #262727;
  min-width: 320px;
  line-height: 1.4;
  letter-spacing: -0.02em;
}

p, b, h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

b {
  font-weight: 600;
}

a {
  text-decoration: none;
  color:unset;
}

body.modal-open {
  overflow-y: hidden;
}

@-moz-keyframes fade {
  from {
    background: #777;
  }
}
@-webkit-keyframes fade {
  from {
    background: #777;
  }
}

.loading-spinner {
  width: 32px;
  height: 32px;
  -webkit-transition: opacity ease-out 450ms;
  -o-transition: opacity ease-out 450ms;
  transition: opacity ease-out 450ms;
  -webkit-animation: 0.6s steps(12) 0s infinite cloudos-loading-spinner;
  animation: 0.6s steps(12) 0s infinite cloudos-loading-spinner;
}

@-webkit-keyframes cloudos-loading-spinner {
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes cloudos-loading-spinner {
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}


.black-circle-spinner {
  background: no-repeat center/20px url("circle_spinner_black.svg");
  width: 20px;
  height: 20px;
  animation: 1s linear infinite loading-spinner;
  -webkit-animation: 1s 0s linear infinite loading-spinner;
}

@keyframes loading-spinner {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes loading-spinner {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}